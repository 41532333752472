import get from "lodash/get";
import { format } from "date-fns";

export const getHeadline = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ["alternative", "home", "default", "headline"], story.headline);
};

export const getSectionName = (story) => {
  return get(story, ["sections", "0", "display-name"]) || get(story, ["sections", "0", "name"]);
};

export const getSectionName2 = (story, index = 1) => {
  const urlObj = new URL(story.url);
  const pathParts = urlObj.pathname.split("/");
  const sectionName = pathParts[pathParts.length - (index + 1)];
  return sectionName || null;
};

export const getSectionSlug = (story, index = 0) => get(story, ["sections", index, "slug"], "");
export const getSectionSlug2 = (config, story, index = 0) => {
  const urlObj = new URL(story.url);
  const pathParts = urlObj.pathname.split("/");
  const sectionName = pathParts[pathParts.length - (index + 1)];
  const sectionSlug = config.sections.find((section) => section.slug === sectionName);
  return sectionSlug ? sectionSlug?.collection?.slug : null;
};

export const getSectionCollectionSlug = (config, sectionName) => {
  const section = config.sections.find((section) => section.slug === sectionName);
  return section ? section?.collection?.slug : null;
};

export const handleTimeFormat = (timestamp) => {
  if (!timestamp) return;
  const GMTTime = new Date(timestamp);
  const offsetMilliseconds = 5.5 * 60 * 60 * 1000;
  const ISTTime = GMTTime.getTime() + offsetMilliseconds;
  return format(new Date(ISTTime), "dd MMMM yyyy, HH:mm");
};

export const storyPageBodyAd = (story, isImmersiveStory) => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmSource = searchParams.get("utm_source");
  const ele = document.querySelector("#text-element-with-ad p");

  const allPtags = document.querySelectorAll(".story-cards p");
  // const allStoryElements = document.querySelectorAll(".story-cards .story-element");

  if (
    ele &&
    story["story-template"] !== "dh-exclusive" &&
    story["story-template"] !== "immersive" &&
    (isImmersiveStory ? (allPtags.length > 1 ? true : false) : true)
  ) {
    const adText = document.createElement("div");
    adText.textContent = "ADVERTISEMENT";
    adText.id = "container-text";
    ele.appendChild(adText);

    const desktopAd = document.createElement("div");
    desktopAd.id = utmSource === "izooto" ? "div-gpt-ad-1711971649563-0" : "div-gpt-ad-1680156704803-0";
    desktopAd.className = "hide-mobile story-body-ad";

    const mobileAd = document.createElement("div");
    mobileAd.id = utmSource === "izooto" ? "div-gpt-ad-1711972030627-0" : "div-gpt-ad-1680157855094-0";
    mobileAd.className = "hide-desktop story-body-ad";
    ele.append(desktopAd);
    ele.append(mobileAd);
  }
};

export const updateUrl = (nextPage, pageNumber) => {
  const urlPath = window.location.pathname.replace(/\/$/, "");
  const urlParams = urlPath.split("/") || [];
  const pageNumPosition = pageNumber ? urlParams.length - 1 : urlParams.length;
  urlParams[pageNumPosition] = nextPage;
  const newPath = urlParams.join("/");
  const params = window.location.search;
  window.history.replaceState(null, null, `${window.location.origin}${newPath}${params}`);
};

export const extractTextFromHtml = (htmlString) => {
  const re = /<[^>]*>/g;
  const text = htmlString.replace(re, "");
  return text.replace(/&nbsp;/g, " ") || "";
};

export function addTokensToLocalStorage(access_token, refresh_token) {
  if (window) {
    localStorage.setItem("access_token", access_token.replaceAll(" ", "+"));
    localStorage.setItem("refresh_token", refresh_token.replaceAll(" ", "+"));
  }
}

export function getUserDetails() {
  const url = "/get-user-details";
  const timestamp = Date.now();
  // Adding a timestamp to the URL to ensure it's always unique and server treats it as new request.
  const newUrl = `${url}?timestamp=${timestamp}`;

  const access_token = localStorage.getItem("access_token");

  if (access_token?.length > 0) {
    return fetch(newUrl, { headers: new Headers({ Authorization: `Bearer ${access_token}` }) })
      .then(async (response) => {
        if (response?.status === 401) {
          return await refreshAccessToken(getUserDetails)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return err;
            });
        } else {
          return response.json();
        }
      })
      .then((userdata) => {
        return userdata;
      })
      .catch((err) => {
        return err;
      });
  }
}
export function putUserDetails(data) {
  const url = "/put-user-details";

  const access_token = localStorage.getItem("access_token");

  if (access_token?.length > 0) {
    return fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${access_token}`, "Content-Type": "application/json" }),
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        if (response?.status === 401) {
          return await refreshAccessToken(putUserDetails)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return err;
            });
        } else {
          return response.json();
        }
      })
      .then((userdata) => {
        return userdata;
      })
      .catch((err) => {
        return err;
      });
  }
}

export function refreshAccessToken(callbackFunction) {
  const url = "/refresh-token";

  const refresh_token = localStorage.getItem("refresh_token");

  if (refresh_token?.length > 0) {
    return fetch(url, { method: "POST", headers: new Headers({ Authorization: `Bearer ${refresh_token}` }) })
      .then((response) => {
        if (!response.ok) {
          throw new Error(JSON.stringify({ status: response.status, statusText: response.statusText }));
        } else {
          return response.json();
        }
      })
      .then((tokendata) => {
        addTokensToLocalStorage(tokendata?.["st-access-token"], tokendata?.["st-refresh-token"]);
        callbackFunction();
      })
      .catch((err) => {
        let catchError;
        try {
          //for error thrown if status code is not 200
          catchError = JSON.parse(err.message);
        } catch (error) {
          catchError = err;
        }
        return catchError;
      });
  }
}

export function generateTokenForCommenting(userObject) {
  return fetch("/generate-comments-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userObject)
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function signInUpWithGoogleOneTap(id_token) {
  const signInBody = {
    thirdPartyId: "google",
    clientType: "web",
    oAuthTokens: {
      id_token
    }
  };

  return fetch("/sign-in-with-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      rid: "thirdpartypasswordless"
    },
    body: JSON.stringify(signInBody)
  })
    .then((response) => response.json())
    .then((tokendata) => {
      addTokensToLocalStorage(tokendata?.["st-access-token"], tokendata?.["st-refresh-token"]);
    })
    .catch((error) => {
      console.log(error);
    });
}
