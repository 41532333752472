import React from "react";
import { string, number, object, bool } from "prop-types";
import { getHeadline } from "../../helper/utils";
import get from "lodash.get";
import LiveIcon from "../icons/live-icon";

import "./headline.m.css";

const Headline = ({
  text,
  headerType,
  headerLevel,
  story,
  lineClamp,
  alternativeHeadline,
  showSectionName,
  isElectionWidget,
  ...props
}) => {
  const sectionName = get(story, ["sections", "0", "display-name"]) || get(story, ["sections", "0", "name"]);
  const isClosed = get(story, ["metadata", "is-closed"], false);
  const HeaderTag = "h" + headerLevel;
  const headline = alternativeHeadline ? getHeadline(story) : text;
  const lineClampClass = `line-clamp line-clamp-${lineClamp}`;
  const closeLiveBlog = story?.["first-published-at"] < 1688185800000;

  return (
    <HeaderTag
      className={`headline headline-type-${headerType} ${lineClampClass}`}
      styleName={`headline headline-type-${headerType}`}
      // {...props}
    >
      {showSectionName && <span styleName="section-name">{sectionName}</span>}
      {story?.["story-template"] === "live-blog" && !isClosed && !closeLiveBlog && (
        <LiveIcon blinkingColor={isElectionWidget ? "white" : "red"} />
      )}
      {headline?.length ? headline : text}
    </HeaderTag>
  );
};

Headline.defaultProps = {
  headerType: 1,
  headerLevel: 3,
  lineClamp: 3,
  alternativeHeadline: true,
  showSectionName: false,
  isElectionWidget: false
};

Headline.propTypes = {
  text: string,
  story: object,
  headerType: number,
  headerLevel: number,
  useAlternateHeadline: bool,
  alternativeHeadline: bool,
  lineClamp: number,
  isElectionWidget: bool
};

export default Headline;
