import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { WithLazy } from "@quintype/components";
import { DfpComponent } from "./dfp-component";
import "./ads.m.css";
import useAdType from "../helper/useAdType";

const AdSlot = ({ id, ad_type, adStyleName = "", isExternalAdPath = false }) => {
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const networkId = get(qtState, ["config", "ads-config", "network_id"], "");
  const adConfig = get(qtState, ["config", "ads-config", "slots", ad_type], {});
  const { sizes, ad_unit, view_port_size_mapping, externalPath } = adConfig;
  const adUnitPath = isExternalAdPath ? externalPath : `${networkId}/${ad_unit}`;

  const DfpComponentWrapper = () => (
    <DfpComponent
      adStyleName={adStyleName}
      id={id}
      path={adUnitPath}
      size={sizes}
      viewPortSizeMapping={view_port_size_mapping}
      type="top-ad"
    />
  );

  if (ad_unit === "DH_MWeb_AT_Mid" || ad_unit === "DH_Desktop_AT_Mid") {
    return <DfpComponentWrapper />;
  }
  return <WithLazy>{() => <DfpComponentWrapper />}</WithLazy>;
};

const AdContainer = React.memo(
  ({ id, desktop, mobile, AdClassName = "", showText = true, isExternalAdPath = false }) => {
    return (
      <div styleName="Adcontainer">
        {showText ? (
          <span styleName="container-text" className="container-text">
            ADVERTISEMENT
          </span>
        ) : null}

        <div className="ad-background" styleName={`ad-background ${AdClassName}`}>
          {global.width >= 768 && desktop ? (
            <div styleName="desktop">
              <AdSlot id={`${id}`} ad_type={desktop} isExternalAdPath={isExternalAdPath} />
            </div>
          ) : null}

          {global.width <= 768 && mobile ? (
            <div styleName="mobile">
              <AdSlot id={`${id}`} ad_type={mobile} isExternalAdPath={isExternalAdPath} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
);

const HeaderDesktopAd = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], "home-page"));
  const adType = useAdType();
  const adsId = {
    "home-page": "div-gpt-ad-1680156194475-0",
    "story-page": "div-gpt-ad-1680156622758-0",
    "izooto-top": "div-gpt-ad-1711971157718-0"
  };
  const ads = {
    "home-page": "DH_Desktop_HP_Billboard_Top",
    "story-page": "DH_Desktop_AT_Billboard",
    "izooto-top": "DH_Desktop_AT_Billboard_ym"
  };

  return (
    <div styleName="header-container">
      <AdContainer
        AdClassName="standard-970x90"
        id={adType === "izooto" ? adsId["izooto-top"] : adsId[pageType] || "div-gpt-ad-1680156194475-0"}
        desktop={adType === "izooto" ? ads["izooto-top"] : ads[pageType] || "DH_Desktop_HP_Billboard_Top"}
        isExternalAdPath={adType === "izooto" ? true : false}
      />
    </div>
  );
};

const OutOfPageAd = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  return (
    <>
      {!isMobile ? (
        <AdContainer
          className="hide-mobile"
          showText={false}
          id="div-gpt-ad-1680157592679-0"
          desktop="DH_Desktop_Interstitial"
        />
      ) : (
        <AdContainer
          className="hide-desktop"
          showText={false}
          id="div-gpt-ad-1680157627881-0"
          mobile="DH_Mobile_Interstitial"
        />
      )}
    </>
  );
};

export { AdSlot, AdContainer, HeaderDesktopAd, OutOfPageAd };
